import React from 'react'
import { graphql } from 'gatsby'

import { PrismicSubpage } from '@models/page.models'

import { Layout } from '@components/layout'
import { Slice } from '@components/slice'
type Props = {
  data: PrismicSubpage
  pageContext: any
}

const Subpage: React.FC<Props> = props => {
  const metaData = props.data?.meta_data
  const seo = props.data?.seo
  let header = props.data?.header
  const main = props.data?.main?.data
  const footer = props.data?.footer
  const theme = props.pageContext?.theme
  const slices = main?.body
  header = { ...header, socialLinks: footer?.data?.social_links }

  return (
    <Layout
      seo={seo}
      header={header}
      footer={footer}
      theme={theme}
      activeMetaData={metaData}
    >
      <Slice allSlices={slices} />
    </Layout>
  )
}

export default Subpage

export const pageQuery = graphql`
  query Subpage($uid: String, $locale: String) {
    seo: prismicSubpage(uid: { eq: $uid }) {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url(imgixParams: { width: 1200, height: 630 })
        }
      }
    }
    header: prismicHeader(lang: { eq: $locale }) {
      ...header
    }
    main: prismicSubpage(uid: { eq: $uid }, lang: { eq: $locale }) {
      data {
        body {
          ... on PrismicSubpageDataBodyHeroWithImage {
            ...heroWithImage
          }
          ... on PrismicSubpageDataBodyContent {
            id
            slice_type
            slice_label
            primary {
              content {
                raw
              }
            }
            items {
              content_in_column {
                raw
              }
            }
          }
          ... on PrismicSubpageDataBodyProblemSolutionOutcome {
            ...problemOutcome
          }
          ... on PrismicSubpageDataBodyNextPostLink {
            ...nextPostLink
          }
        }
      }
    }
    footer: prismicFooter(lang: { eq: $locale }) {
      ...footer
    }
  }
`
